<template>
  <div class="header_wrap fixed-top">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <!-- logo -->
        <router-link to="/" class="navbar-brand" @click="mainPage()">
          <img src="https://holaprimetest.s3.us-east-2.amazonaws.com/images/1731739937266.webp" alt="Hola Prime Logo"
            class="img-fluid header_logo" loading="lazy" />
        </router-link>

        <!-- toggle button -->
        <button class="navbar-toggler order-3 order-lg-2" type="button" :class="isExpanded ? '' : 'd-none'"
          @click="toggleNavbar">
          <div class="navbar-toggler-icon line-1"></div>
          <div class="navbar-toggler-icon line-2"></div>
          <div class="navbar-toggler-icon line-3"></div>
        </button>

        <!-- nav links -->
        <div class="collapse navbar-collapse order-4 order-lg-3 " :class="{ show: isExpanded }">
          <ul class="navbar-nav mb-2 mb-lg-0 mx-auto d-none d-lg-flex">
            <!-- About Us -->
            <li class="nav-item dropdown">
              <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>About Us</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 512 512">
                  <path
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                </svg>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink @click="toggleNavbar" to="/about-us" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span> Who we are</span>
                  </RouterLink>
                </li>
                <li v-if="!originStore?.isOriginInLocations">
                  <RouterLink @click="toggleNavbar" to="/teams" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Our Team</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/contact-us" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Contact Us</span>
                  </RouterLink>
                </li>
              </ul>
            </li>

            <!-- Funding Plans -->
            <li class="nav-item dropdown" v-if="!originStore?.isOriginInLocations">
              <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>Funding Plans</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 512 512">
                  <path
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                </svg>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink @click="toggleNavbar" to="/pro-challenge" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Pro Challenges</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/prime-challenge" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Prime Challenges</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/scaling" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Scaling Plan</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/trading-rules" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Trading Rules</span>
                  </RouterLink>
                </li>
              </ul>
            </li>

            <!-- Trading -->
            <li class="nav-item dropdown">
              <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>Trading</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 512 512">
                  <path
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                </svg>
              </a>
              <ul v-if="!originStore?.isOriginInLocations" class="dropdown-menu">
                <li>
                  <RouterLink @click="toggleNavbar" to="/leaderboard" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Leaderboard</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/transparency-report" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Transparency Report</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/trading-platforms" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Platforms</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/trading-tools" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Trading Tools</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/hours-payout" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>1-Hour Payouts</span>
                  </RouterLink>
                </li>
              </ul>
              <ul v-else class="dropdown-menu">
                <li>
                  <RouterLink @click="toggleNavbar" to="/trading-accounts" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Trading Account</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/trading-instruments" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Trading Instruments</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/trading-condition" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Trading Conditions</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/funding" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Funding</span>
                  </RouterLink>
                </li>
              </ul>
            </li>

            <!-- FAQs -->
            <li class="nav-item" v-if="!originStore?.isOriginInLocations">
              <RouterLink @click="toggleNavbar" to="/faq" class="nav-link">FAQs</RouterLink>
            </li>

            <!-- Academy -->
            <li class="nav-item dropdown">
              <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>Academy</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 512 512">
                  <path
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                </svg>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink @click="toggleNavbar" to="/prime-academy" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Prime Academy</span>
                  </RouterLink>
                </li>
                <li>
                  <RouterLink @click="toggleNavbar" to="/trading-coach" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Trading Coaches</span>
                  </RouterLink>
                </li>
                <li v-if="!originStore?.isOriginInLocations">
                  <RouterLink @click="toggleNavbar" to="/blogs" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Blogs</span>
                  </RouterLink>
                </li>
              </ul>
            </li>

            <!-- Affiliate -->
            <li class="nav-item dropdown">
              <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>Affiliate</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 512 512">
                  <path
                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                </svg>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <RouterLink @click="toggleNavbar" to="/affiliate-program" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Partners</span>
                  </RouterLink>
                </li>
                <li v-if="!originStore?.isOriginInLocations">
                  <RouterLink @click="toggleNavbar" to="/faq/affilate" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>FAQs</span>
                  </RouterLink>
                </li>
                <li v-if="!originStore?.isOriginInLocations">
                  <a @click="toggleNavbar" href="https://trader.holaprime.com/en/sign-in" target="_blank"
                    class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Login</span>
                  </a>
                </li>
                <li v-else>
                  <RouterLink @click="toggleNavbar" to="/404" class="dropdown-item">
                    <svg class="d-lg-none" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                      fill="none">
                      <path d="M4.23438 12.5H20.2344M20.2344 12.5L14.2344 6.5M20.2344 12.5L14.2344 18.5"
                        stroke="#00F1AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span>Login</span>
                  </RouterLink>
                </li>
              </ul>
            </li>
          </ul>

          <div class="new-navlist d-lg-none">
            <ul class="nav list-unstyled flex-column flex-nowrap justify-content-between">
              <li class="nav-item">
                <RouterLink to="/" class="nav-link">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M9 21V13.6C9 13.0399 9 12.7599 9.10899 12.546C9.20487 12.3578 9.35785 12.2049 9.54601 12.109C9.75992 12 10.0399 12 10.6 12H13.4C13.9601 12 14.2401 12 14.454 12.109C14.6422 12.2049 14.7951 12.3578 14.891 12.546C15 12.7599 15 13.0399 15 13.6V21M11.0177 2.764L4.23539 8.03912C3.78202 8.39175 3.55534 8.56806 3.39203 8.78886C3.24737 8.98444 3.1396 9.20478 3.07403 9.43905C3 9.70352 3 9.9907 3 10.5651V17.8C3 18.9201 3 19.4801 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4801 21 18.9201 21 17.8V10.5651C21 9.9907 21 9.70352 20.926 9.43905C20.8604 9.20478 20.7526 8.98444 20.608 8.78886C20.4447 8.56806 20.218 8.39175 19.7646 8.03913L12.9823 2.764C12.631 2.49075 12.4553 2.35412 12.2613 2.3016C12.0902 2.25526 11.9098 2.25526 11.7387 2.3016C11.5447 2.35412 11.369 2.49075 11.0177 2.764Z"
                      stroke="#5A607F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span> Home</span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink to="/about-us" class="nav-link">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M7 11.5V14M7 11.5V5.5C7 5.10218 7.15804 4.72064 7.43934 4.43934C7.72064 4.15804 8.10218 4 8.5 4C8.89782 4 9.27936 4.15804 9.56066 4.43934C9.84196 4.72064 10 5.10218 10 5.5M7 11.5C7 11.1022 6.84196 10.7206 6.56066 10.4393C6.27936 10.158 5.89782 10 5.5 10C5.10218 10 4.72064 10.158 4.43934 10.4393C4.15804 10.7206 4 11.1022 4 11.5V13.5C4 15.4891 4.79018 17.3968 6.1967 18.8033C7.60322 20.2098 9.51088 21 11.5 21C13.4891 21 15.3968 20.2098 16.8033 18.8033C18.2098 17.3968 19 15.4891 19 13.5V8.5C19 8.10218 18.842 7.72064 18.5607 7.43934C18.2794 7.15804 17.8978 7 17.5 7C17.1022 7 16.7206 7.15804 16.4393 7.43934C16.158 7.72064 16 8.10218 16 8.5M10 5.5V11M10 5.5V4.5C10 4.10218 10.158 3.72064 10.4393 3.43934C10.7206 3.15804 11.1022 3 11.5 3C11.8978 3 12.2794 3.15804 12.5607 3.43934C12.842 3.72064 13 4.10218 13 4.5V5.5M16 8.5V5.5C16 5.10218 15.842 4.72064 15.5607 4.43934C15.2794 4.15804 14.8978 4 14.5 4C14.1022 4 13.7206 4.15804 13.4393 4.43934C13.158 4.72064 13 5.10218 13 5.5M16 8.5V11M13 5.5V11"
                      stroke="#5A607F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span> Who we are</span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink to="/teams" class="nav-link">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M17 20H22V18C22 17.3765 21.8057 16.7686 21.4441 16.2606C21.0826 15.7527 20.5718 15.37 19.9827 15.1658C19.3937 14.9615 18.7556 14.9459 18.1573 15.121C17.5589 15.2962 17.03 15.6534 16.644 16.143M17 20H7M17 20V18C17 17.344 16.874 16.717 16.644 16.143M16.644 16.143C16.2726 15.215 15.6318 14.4195 14.804 13.8591C13.9762 13.2988 12.9996 12.9993 12 12.9993C11.0004 12.9993 10.0238 13.2988 9.196 13.8591C8.36825 14.4195 7.72736 15.215 7.356 16.143M7 20H2V18C2.00005 17.3765 2.19434 16.7686 2.55586 16.2606C2.91739 15.7527 3.42819 15.37 4.01725 15.1658C4.60632 14.9615 5.24438 14.9459 5.84274 15.121C6.4411 15.2962 6.97003 15.6534 7.356 16.143M7 20V18C7 17.344 7.126 16.717 7.356 16.143M15 7C15 7.79565 14.6839 8.55871 14.1213 9.12132C13.5587 9.68393 12.7956 10 12 10C11.2044 10 10.4413 9.68393 9.87868 9.12132C9.31607 8.55871 9 7.79565 9 7C9 6.20435 9.31607 5.44129 9.87868 4.87868C10.4413 4.31607 11.2044 4 12 4C12.7956 4 13.5587 4.31607 14.1213 4.87868C14.6839 5.44129 15 6.20435 15 7ZM21 10C21 10.5304 20.7893 11.0391 20.4142 11.4142C20.0391 11.7893 19.5304 12 19 12C18.4696 12 17.9609 11.7893 17.5858 11.4142C17.2107 11.0391 17 10.5304 17 10C17 9.46957 17.2107 8.96086 17.5858 8.58579C17.9609 8.21071 18.4696 8 19 8C19.5304 8 20.0391 8.21071 20.4142 8.58579C20.7893 8.96086 21 9.46957 21 10ZM7 10C7 10.5304 6.78929 11.0391 6.41421 11.4142C6.03914 11.7893 5.53043 12 5 12C4.46957 12 3.96086 11.7893 3.58579 11.4142C3.21071 11.0391 3 10.5304 3 10C3 9.46957 3.21071 8.96086 3.58579 8.58579C3.96086 8.21071 4.46957 8 5 8C5.53043 8 6.03914 8.21071 6.41421 8.58579C6.78929 8.96086 7 9.46957 7 10Z"
                      stroke="#5A607F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span> Our Team</span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink to="/contact-us" class="nav-link" @click="toggleNavbar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M3 12H21M3 6H21M3 18H21" stroke="black" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  <span> Contact us</span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink to="/prime-challenge" class="nav-link" @click="toggleNavbar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M13.5295 8.35186C12.9571 8.75995 12.2566 9 11.5 9C9.567 9 8 7.433 8 5.5C8 3.567 9.567 2 11.5 2C12.753 2 13.8522 2.65842 14.4705 3.64814M6 20.0872H8.61029C8.95063 20.0872 9.28888 20.1277 9.61881 20.2086L12.3769 20.8789C12.9753 21.0247 13.5988 21.0388 14.2035 20.9214L17.253 20.3281C18.0585 20.1712 18.7996 19.7854 19.3803 19.2205L21.5379 17.1217C22.154 16.5234 22.154 15.5524 21.5379 14.9531C20.9832 14.4134 20.1047 14.3527 19.4771 14.8103L16.9626 16.6449C16.6025 16.9081 16.1643 17.0498 15.7137 17.0498H13.2855L14.8311 17.0498C15.7022 17.0498 16.4079 16.3633 16.4079 15.5159V15.2091C16.4079 14.5055 15.9156 13.892 15.2141 13.7219L12.8286 13.1417C12.4404 13.0476 12.0428 13 11.6431 13C10.6783 13 8.93189 13.7988 8.93189 13.7988L6 15.0249M20 6.5C20 8.433 18.433 10 16.5 10C14.567 10 13 8.433 13 6.5C13 4.567 14.567 3 16.5 3C18.433 3 20 4.567 20 6.5ZM2 14.6L2 20.4C2 20.9601 2 21.2401 2.10899 21.454C2.20487 21.6422 2.35785 21.7951 2.54601 21.891C2.75992 22 3.03995 22 3.6 22H4.4C4.96005 22 5.24008 22 5.45399 21.891C5.64215 21.7951 5.79513 21.6422 5.89101 21.454C6 21.2401 6 20.9601 6 20.4V14.6C6 14.0399 6 13.7599 5.89101 13.546C5.79513 13.3578 5.64215 13.2049 5.45399 13.109C5.24008 13 4.96005 13 4.4 13L3.6 13C3.03995 13 2.75992 13 2.54601 13.109C2.35785 13.2049 2.20487 13.3578 2.10899 13.546C2 13.7599 2 14.0399 2 14.6Z"
                      stroke="#5A607F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span> Funding Plans</span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink to="/trading-rules" class="nav-link" @click="toggleNavbar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M6.5 20H5C3.89543 20 3 19.1046 3 18V4C3 2.89543 3.89543 2 5 2H19C20.1046 2 21 2.89543 21 4V18C21 19.1046 20.1046 20 19 20H17.5M12 19C13.6569 19 15 17.6569 15 16C15 14.3431 13.6569 13 12 13C10.3431 13 9 14.3431 9 16C9 17.6569 10.3431 19 12 19ZM12 19L12.0214 18.9998L8.82867 22.1926L6.00024 19.3641L9.01965 16.3447M12 19L15.1928 22.1926L18.0212 19.3641L15.0018 16.3447M9 6H15M7 9.5H17"
                      stroke="#5A607F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span> Trading Rules</span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink to="/leaderboard" class="nav-link" @click="toggleNavbar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M12 15C8.68629 15 6 12.3137 6 9V3.44444C6 3.0306 6 2.82367 6.06031 2.65798C6.16141 2.38021 6.38021 2.16141 6.65798 2.06031C6.82367 2 7.0306 2 7.44444 2H16.5556C16.9694 2 17.1763 2 17.342 2.06031C17.6198 2.16141 17.8386 2.38021 17.9397 2.65798C18 2.82367 18 3.0306 18 3.44444V9C18 12.3137 15.3137 15 12 15ZM12 15V18M18 4H20.5C20.9659 4 21.1989 4 21.3827 4.07612C21.6277 4.17761 21.8224 4.37229 21.9239 4.61732C22 4.80109 22 5.03406 22 5.5V6C22 6.92997 22 7.39496 21.8978 7.77646C21.6204 8.81173 20.8117 9.62038 19.7765 9.89778C19.395 10 18.93 10 18 10M6 4H3.5C3.03406 4 2.80109 4 2.61732 4.07612C2.37229 4.17761 2.17761 4.37229 2.07612 4.61732C2 4.80109 2 5.03406 2 5.5V6C2 6.92997 2 7.39496 2.10222 7.77646C2.37962 8.81173 3.18827 9.62038 4.22354 9.89778C4.60504 10 5.07003 10 6 10M7.44444 22H16.5556C16.801 22 17 21.801 17 21.5556C17 19.5919 15.4081 18 13.4444 18H10.5556C8.59188 18 7 19.5919 7 21.5556C7 21.801 7.19898 22 7.44444 22Z"
                      stroke="#5A607F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span> Leaderboard</span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink to="/transparency-report" class="nav-link" @click="toggleNavbar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                    <path
                      d="M5 15V13M8 15V11M11 15V9M13 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H8.586C8.8512 1.00006 9.10551 1.10545 9.293 1.293L14.707 6.707C14.8946 6.89449 14.9999 7.1488 15 7.414V17C15 17.5304 14.7893 18.0391 14.4142 18.4142C14.0391 18.7893 13.5304 19 13 19Z"
                      stroke="#5A607F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span> Transparency Report</span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink to="/trading-platforms" class="nav-link" @click="toggleNavbar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M7 12L10 9L13 12L17 8M8 21L12 17L16 21M3 4H21M4 4H20V16C20 16.2652 19.8946 16.5196 19.7071 16.7071C19.5196 16.8946 19.2652 17 19 17H5C4.73478 17 4.48043 16.8946 4.29289 16.7071C4.10536 16.5196 4 16.2652 4 16V4Z"
                      stroke="#5A607F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span> Platforms</span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink to="/trading-tools" class="nav-link" @click="toggleNavbar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M21 13.255C18.1405 14.4112 15.0844 15.0038 12 15C8.817 15 5.78 14.38 3 13.255M12 12H12.01M16 6V4C16 3.46957 15.7893 2.96086 15.4142 2.58579C15.0391 2.21071 14.5304 2 14 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V6H16ZM5 20H19C19.5304 20 20.0391 19.7893 20.4142 19.4142C20.7893 19.0391 21 18.5304 21 18V8C21 7.46957 20.7893 6.96086 20.4142 6.58579C20.0391 6.21071 19.5304 6 19 6H5C4.46957 6 3.96086 6.21071 3.58579 6.58579C3.21071 6.96086 3 7.46957 3 8V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20Z"
                      stroke="#5A607F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span> Trading Tools</span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink to="/prime-academy" class="nav-link" @click="toggleNavbar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M17 14.5001V11.4945C17 11.315 17 11.2253 16.9727 11.146C16.9485 11.076 16.9091 11.0122 16.8572 10.9592C16.7986 10.8993 16.7183 10.8592 16.5578 10.779L12 8.50006M4 9.50006V16.3067C4 16.6786 4 16.8645 4.05802 17.0274C4.10931 17.1713 4.1929 17.3016 4.30238 17.4082C4.42622 17.5287 4.59527 17.6062 4.93335 17.7612L11.3334 20.6945C11.5786 20.8069 11.7012 20.8631 11.8289 20.8853C11.9421 20.9049 12.0579 20.9049 12.1711 20.8853C12.2988 20.8631 12.4214 20.8069 12.6666 20.6945L19.0666 17.7612C19.4047 17.6062 19.5738 17.5287 19.6976 17.4082C19.8071 17.3016 19.8907 17.1713 19.942 17.0274C20 16.8645 20 16.6786 20 16.3067V9.50006M2 8.50006L11.6422 3.67895C11.7734 3.61336 11.839 3.58056 11.9078 3.56766C11.9687 3.55622 12.0313 3.55622 12.0922 3.56766C12.161 3.58056 12.2266 3.61336 12.3578 3.67895L22 8.50006L12.3578 13.3212C12.2266 13.3868 12.161 13.4196 12.0922 13.4325C12.0313 13.4439 11.9687 13.4439 11.9078 13.4325C11.839 13.4196 11.7734 13.3868 11.6422 13.3212L2 8.50006Z"
                      stroke="#5A607F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span> Hola Prime Academy</span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink to="/trading-coach" class="nav-link" @click="toggleNavbar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M10 14.5347C11.2335 13.8218 12.7663 13.8218 13.9999 14.5347M2 15L2.70149 7.98511C2.72808 7.71915 2.74138 7.58617 2.76178 7.47208C3.00222 6.12702 4.1212 5.11436 5.48352 5.00894C5.59907 5 5.73271 5 6 5M22 15L21.2985 7.98511C21.2719 7.71916 21.2586 7.58617 21.2382 7.47208C20.9978 6.12702 19.8788 5.11436 18.5165 5.00894C18.4009 5 18.2673 5 18 5M8.82843 12.1716C10.3905 13.7337 10.3905 16.2663 8.82843 17.8284C7.26634 19.3905 4.73367 19.3905 3.17157 17.8284C1.60948 16.2663 1.60948 13.7337 3.17157 12.1716C4.73366 10.6095 7.26633 10.6095 8.82843 12.1716ZM20.8284 12.1716C22.3905 13.7337 22.3905 16.2663 20.8284 17.8284C19.2663 19.3905 16.7337 19.3905 15.1716 17.8284C13.6095 16.2663 13.6095 13.7337 15.1716 12.1716C16.7337 10.6095 19.2663 10.6095 20.8284 12.1716Z"
                      stroke="#5A607F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span> Trading Coaches</span>
                </RouterLink>
              </li>
              <li class="nav-item">
                <RouterLink to="/blogs" class="nav-link" @click="toggleNavbar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M17 9H3M17 9C17.5304 9 18.0391 9.21071 18.4142 9.58579C18.7893 9.96086 19 10.4696 19 11V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V11C1 10.4696 1.21071 9.96086 1.58579 9.58579C1.96086 9.21071 2.46957 9 3 9M17 9V7C17 6.46957 16.7893 5.96086 16.4142 5.58579C16.0391 5.21071 15.5304 5 15 5M3 9V7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5M15 5V3C15 2.46957 14.7893 1.96086 14.4142 1.58579C14.0391 1.21071 13.5304 1 13 1H7C6.46957 1 5.96086 1.21071 5.58579 1.58579C5.21071 1.96086 5 2.46957 5 3V5M15 5H5"
                      stroke="#5A607F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span> Blogs</span>
                </RouterLink>
              </li>
            </ul>
          </div>

          <!-- btns group -->
          <div class="btns_group ms-auto order-2 order-lg-4 d-flex d-md-none mt-3 mt-md-0"
            :class="isExpanded ? '' : 'd-none'">
            <a href="https://trader.holaprime.com/en/sign-in"
              class="btn_primary text-decoration-none rounded-pill w-100" target="_blank" id="sign_in">
              <span>Log In</span>
            </a>
            <a href="https://trader.holaprime.com/en/sign-up"
              class="btn_primary outline-btn text-decoration-none rounded-pill w-100" aria-current="page"
              target="_blank" id="sign_up">
              <span>Sign up</span>
            </a>
          </div>
        </div>

        <!-- btns group -->
        <div class="btns_group ms-auto order-2 order-lg-4 d-none d-md-flex me-md-3 me-lg-0">
          <a href="https://trader.holaprime.com/en/sign-in" class="dashboard_btn text-decoration-none" target="_blank"
            id="sign_in">
            <span>Login</span>
          </a>
          <a href="https://trader.holaprime.com/en/sign-up"
            class="dashboard_btn dashboard_outline_btn text-decoration-none" aria-current="page" target="_blank"
            id="sign_up">
            Free Trial
          </a>
        </div>

        <!-- btns group -->
        <div class="btns_group ms-auto order-2 order-lg-4 d-md-none me-3">
          <a href="https://trader.holaprime.com/en/sign-up"
            class="btn_primary rounded-pill text-decoration-none px-4 py-2" aria-current="page" target="_blank"
            id="sign_up">
            Free Trial
          </a>
        </div>
      </div>
    </nav>
  </div>

  <div class="header-bottom d-lg-none">
    <ul class="nav list-unstyled flex-nowrap justify-content-between">
      <li class="nav-item">
        <RouterLink to="/" class="nav-link">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_5271_55446)">
              <path
                d="M23.1213 9.06887L15.5363 1.48287C14.5975 0.546856 13.3259 0.0212402 12.0003 0.0212402C10.6746 0.0212402 9.40301 0.546856 8.46426 1.48287L0.879256 9.06887C0.599682 9.34665 0.378026 9.67717 0.227139 10.0413C0.0762514 10.4053 -0.00086626 10.7958 0.00025622 11.1899V21.0069C0.00025622 21.8025 0.316327 22.5656 0.878936 23.1282C1.44155 23.6908 2.20461 24.0069 3.00026 24.0069H21.0003C21.7959 24.0069 22.559 23.6908 23.1216 23.1282C23.6842 22.5656 24.0003 21.8025 24.0003 21.0069V11.1899C24.0014 10.7958 23.9243 10.4053 23.7734 10.0413C23.6225 9.67717 23.4008 9.34665 23.1213 9.06887ZM15.0003 22.0069H9.00026V18.0729C9.00026 17.2772 9.31633 16.5142 9.87894 15.9515C10.4415 15.3889 11.2046 15.0729 12.0003 15.0729C12.7959 15.0729 13.559 15.3889 14.1216 15.9515C14.6842 16.5142 15.0003 17.2772 15.0003 18.0729V22.0069ZM22.0003 21.0069C22.0003 21.2721 21.8949 21.5264 21.7074 21.714C21.5198 21.9015 21.2655 22.0069 21.0003 22.0069H17.0003V18.0729C17.0003 16.7468 16.4735 15.475 15.5358 14.5373C14.5981 13.5997 13.3263 13.0729 12.0003 13.0729C10.6742 13.0729 9.40241 13.5997 8.46472 14.5373C7.52704 15.475 7.00026 16.7468 7.00026 18.0729V22.0069H3.00026C2.73504 22.0069 2.48069 21.9015 2.29315 21.714C2.10561 21.5264 2.00026 21.2721 2.00026 21.0069V11.1899C2.00118 10.9248 2.10645 10.6709 2.29326 10.4829L9.87826 2.89987C10.442 2.3388 11.2049 2.02381 12.0003 2.02381C12.7956 2.02381 13.5586 2.3388 14.1223 2.89987L21.7073 10.4859C21.8933 10.6731 21.9985 10.9259 22.0003 11.1899V21.0069Z"
                fill="black" />
            </g>
            <defs>
              <clipPath id="clip0_5271_55446">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span> Home</span>
        </RouterLink>
      </li>
      <li class="nav-item">
        <RouterLink to="/pro-challenge" class="nav-link">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_5271_55449)">
              <path
                d="M18.7128 11.9998H13.9998C13.4694 11.9998 12.9606 11.7891 12.5856 11.414C12.2105 11.0389 11.9998 10.5302 11.9998 9.9998V5.2738C12.0011 4.81378 11.8967 4.35961 11.6945 3.94641C11.4923 3.5332 11.1978 3.17202 10.8338 2.8908C10.4888 2.61761 10.0857 2.42738 9.65549 2.33478C9.22531 2.24219 8.7796 2.24973 8.35279 2.3568C5.92844 2.95861 3.78212 4.36986 2.26876 6.35716C0.7554 8.34446 -0.0343381 10.7888 0.0301473 13.2859C0.0946328 15.783 1.00948 18.1832 2.62339 20.0898C4.23731 21.9963 6.45361 23.2949 8.90579 23.7708C11.6629 24.2985 14.5174 23.7522 16.8848 22.2437C19.2522 20.7352 20.9533 18.3787 21.6398 15.6568C21.7473 15.2294 21.7551 14.7831 21.6627 14.3522C21.5703 13.9214 21.3801 13.5175 21.1068 13.1718C20.8222 12.8083 20.459 12.5139 20.0443 12.3109C19.6297 12.1079 19.1744 12.0016 18.7128 11.9998ZM19.7008 15.1678C19.3179 16.7097 18.5323 18.1222 17.4245 19.2608C16.3166 20.3995 14.9261 21.2234 13.3953 21.6484C11.8645 22.0733 10.2483 22.0841 8.71199 21.6795C7.17568 21.2749 5.7744 20.4695 4.65149 19.3456C3.52858 18.2218 2.72433 16.8198 2.32101 15.2832C1.91768 13.7466 1.92975 12.1304 2.35598 10.5999C2.78222 9.06946 3.60732 7.67969 4.74689 6.57274C5.88646 5.4658 7.2996 4.68141 8.84179 4.2998C8.97372 4.26684 9.11154 4.26523 9.24421 4.29509C9.37688 4.32496 9.50071 4.38548 9.60579 4.4718C9.72988 4.56641 9.83051 4.68833 9.89988 4.8281C9.96925 4.96788 10.0055 5.12176 10.0058 5.2778V9.9998C10.0058 11.0607 10.4272 12.0781 11.1774 12.8282C11.9275 13.5784 12.9449 13.9998 14.0058 13.9998H18.7178C18.8752 14.0004 19.0304 14.0368 19.1717 14.1061C19.3131 14.1753 19.4369 14.2758 19.5338 14.3998C19.6188 14.5063 19.678 14.6311 19.7068 14.7643C19.7356 14.8976 19.7332 15.0357 19.6998 15.1678H19.7008Z"
                fill="black" />
              <path
                d="M23.6512 7.44614C23.1908 5.75361 22.2964 4.21064 21.0566 2.96992C19.8167 1.72919 18.2744 0.83373 16.5822 0.372139C16.4048 0.324449 16.2219 0.300242 16.0382 0.300139C15.7739 0.297629 15.5118 0.347507 15.267 0.446892C15.0221 0.546277 14.7994 0.693198 14.6117 0.879165C14.4239 1.06513 14.2749 1.28646 14.1732 1.53036C14.0715 1.77426 14.0192 2.03589 14.0192 2.30014V7.00014C14.0192 7.79579 14.3352 8.55885 14.8978 9.12146C15.4605 9.68407 16.2235 10.0001 17.0192 10.0001H21.7382C22.0468 9.99717 22.3507 9.92308 22.626 9.78362C22.9014 9.64416 23.1409 9.44309 23.326 9.19602C23.5111 8.94896 23.6367 8.66256 23.6931 8.35908C23.7494 8.05559 23.7351 7.74319 23.6512 7.44614ZM21.1532 8.00014H17.0152C16.7499 8.00014 16.4956 7.89478 16.3081 7.70725C16.1205 7.51971 16.0152 7.26536 16.0152 7.00014L16.0072 2.30714C16.0145 2.30185 16.0232 2.29873 16.0322 2.29814H16.0582C17.4203 2.66922 18.6612 3.39105 19.6572 4.39158C20.6532 5.39211 21.3693 6.63636 21.7342 8.00014H21.1532Z"
                fill="black" />
            </g>
            <defs>
              <clipPath id="clip0_5271_55449">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span> Funding Plans</span>
        </RouterLink>
      </li>
      <li class="nav-item">
        <RouterLink to="/prime-challenge" class="nav-link">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_5271_55452)">
              <path
                d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z"
                fill="black" />
              <path
                d="M11.9998 6C11.7346 6 11.4803 6.10536 11.2927 6.29289C11.1052 6.48043 10.9998 6.73478 10.9998 7V11.325L7.62882 13.437C7.40339 13.5778 7.24313 13.8024 7.18331 14.0614C7.12349 14.3204 7.16899 14.5926 7.30982 14.818C7.45065 15.0434 7.67527 15.2037 7.93426 15.2635C8.19324 15.3233 8.46539 15.2778 8.69082 15.137L12.5308 12.737C12.6759 12.6461 12.7952 12.5195 12.8773 12.3692C12.9595 12.219 13.0016 12.0502 12.9998 11.879V7C12.9998 6.73478 12.8945 6.48043 12.7069 6.29289C12.5194 6.10536 12.265 6 11.9998 6Z"
                fill="black" />
            </g>
            <defs>
              <clipPath id="clip0_5271_55452">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span> Start Challenge</span>
        </RouterLink>
      </li>
      <li class="nav-item">
        <div class="nav-link" @click="toggleNavbar">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3 12H21M3 6H21M3 18H21" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <span> More</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { useOriginStore } from '@/stores/originStore';

export default {
  name: "Header",
  data() {
    return {
      isExpanded: false,
      originStore: useOriginStore(),
    };
  },
  methods: {
    toggleNavbar() {
      this.isExpanded = !this.isExpanded;
    },
    mainPage() {
      if (this.isExpanded) {
        this.toggleNavbar();
      }
    },
  },
};
</script>