import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";

// bootstrap
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "@/assets/sass/style.css";

// swiper
import "swiper/css";
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css/thumbs';

import { useOriginStore } from '@/stores/originStore';

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(router);

// if (process.env.NODE_ENV === 'production') {
const originStore = useOriginStore();
originStore.recheckOrigin();
// }

app.mount("#app");