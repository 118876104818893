  <template>
    <Header />
    <div class="page_content" :class="route.name == 'Landing Page' ? 'lp_main' : ''">
      <router-view />
    </div>
    <Footer />
    <LiveChatWidget ref="liveChat" license="18558708" :visibility="visibilityData ? 'minimized' : 'maximized'"
      @visibility-changed="onVisibilityChanged" @new-event="handleNewEvent" />
  </template>

<script setup>
import { ref, onMounted, watch } from "vue";
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import { LiveChatWidget, useWidgetState } from "@livechat/widget-vue";
import { eventBus } from "@/eventBus.js";
import { useRoute } from 'vue-router';

// State variables
const visibilityData = ref(true);
const widgetState = useWidgetState();
const route = useRoute();

// Methods
function handleNewEvent(event) {
  console.log("LiveChatWidget.onNewEvent", event);
}

function openLiveChat() {
  visibilityData.value = !visibilityData.value;

}
function onVisibilityChanged(data) {
  visibilityData.value = widgetState.value?.visibility == "maximized";
}
// Lifecycle hook
onMounted(() => {
  eventBus.value.openLiveChat = openLiveChat;

  setTimeout(() => {
    visibilityData.value = true; // Show the chat after 5 seconds
  }, 3000);
});

watch(route, () => {
  const existingScripts = document.querySelectorAll('script[type="application/ld+json"]');
  existingScripts.forEach(script => {
    document.head.removeChild(script);
  });
});

</script>
