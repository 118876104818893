// stores/origin.js
import { defineStore } from 'pinia';
import axios from 'axios';

export const useOriginStore = defineStore('origin', {
  state: () => ({
    origin: 'null',
  }),
  getters: {
    isOriginInLocations() {
      const locations = ['Singapore', 'Dubai', 'Cyprus'];
      return locations.includes(this.origin);
    }
  },
  actions: {
     // Method to check if the origin has changed
     async recheckOrigin() {
      // const response = await axios.get('https://get.geojs.io/v1/ip/geo.json');
      // const country = response.data.country;  // Get the new country code
      // console.log(country,'country');
      // if (this.origin !== country) {
      //   // If the country has changed, update state and localStorage
      //   this.origin = country;
      // }
    },
  },
});

