import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home.vue"),
  },
  {
    path: "/blackfriday",
    name: "blackfriday home",
    component: () => import("@/views/blackFriday/index.vue"),
  },
  {
    path: "/blackfriday2",
    name: "blackfriday home 2",
    component: () => import("@/views/blackFriday/blackFriday2.vue"),
  },
  {
    path: "/about-us",
    name: "About US",
    component: () => import("@/views/aboutUs.vue"),
  },
  {
    path: "/affiliate-program",
    name: "Affiliate Program",
    component: () => import("@/views/affiliatePartner.vue"),
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: () => import("@/views/contactUs.vue"),
  },
  {
    path: "/faq",
    children: [
      {
        path: "",
        name: "FAQ",
        component: () => import("@/views/Faq/index.vue"),
      },
      {
        path: "about-hola",
        name: "About Hola Prime",
        component: () => import("@/views/Faq/aboutHolaFaq.vue"),
      },
      {
        path: "general",
        name: "General FAQ",
        component: () => import("@/views/Faq/generalFaq.vue"),
      },
      {
        path: "begin",
        name: "How tp Begin FAQ",
        component: () => import("@/views/Faq/beginFaq.vue"),
      },
      {
        path: "funding-plans",
        name: "Funding Plans FAQ",
        component: () => import("@/views/Faq/fundingPlansFaq.vue"),
      },
      {
        path: "scaling",
        name: "Scaling FAQ",
        component: () => import("@/views/Faq/scalingFaq.vue"),
      },
      {
        path: "prime-lessons",
        name: "Prime Lessons",
        component: () => import("@/views/Faq/primeLessonsFaq.vue"),
      },
      {
        path: "payouts",
        name: "Payouts",
        component: () => import("@/views/Faq/payoutsFaq.vue"),
      },
      {
        path: "affilate",
        name: "Affilate FAQ",
        component: () => import("@/views/Faq/affilateFaq.vue"),
      },
      {
        path: "trading-condition",
        name: "condition FAQ",
        component: () => import("@/views/Faq/tradingConditionFaq.vue"),
      },
    ],
  },
  {
    path: "/teams",
    name: "Teams",
    component: () => import("@/views/teams.vue"),
  },
  {
    path: "/trading-rules",
    name: "trading-rules",
    component: () => import("@/views/tradingRules.vue"),
  },
  {
    path: "/prohibited-trading-practices",
    name: "Trading Rules Content",
    component: () => import("@/views/tradingRulesContent.vue"),
  },
  {
    path: "/trading-platforms",
    name: "Trading Platforms",
    component: () => import("@/views/tradingPlatforms.vue"),
  },
  {
    path: "/trading-tools",
    name: "Trading Tools",
    component: () => import("@/views/tradingTools.vue"),
  },

  // education page dropdown
  {
    path: "/blogs",
    children: [
      {
        path: "",
        name: "Blogs",
        component: () => import("@/views/Blogs/index.vue"),
      },
      {
        path: "category/:category",
        name: "Blogs Category",
        component: () => import("@/views/Blogs/blogCategory.vue"),
      },
    ],
  },
  {
    path: "/blog/:slug",
    name: "Blogs Details",
    component: () => import("@/views/Blogs/blogDetails.vue"),
  },
  // {
  //   path: "/alpha-prime",
  //   name: "Alpha Prime",
  //   component: () => import("@/views/alphaPrime.vue"),
  // },
  {
    path: "/transparency-report",
    name: "Transparency Report",
    component: () => import("@/views/transparencyReport.vue"),
  },
  {
    path: "/scaling",
    name: "Scaling",
    component: () => import("@/views/scaling.vue"),
  },

  {
    path: "/pro-challenge",
    name: "Pro challenge",
    component: () => import("@/views/proChallenge.vue"),
  },

  {
    path: "/prime-challenge",
    name: "Prime challenge",
    component: () => import("@/views/primeChallenge.vue"),
  },

  {
    path: "/challenge-comparison",
    name: "Challenge Comparison",
    component: () => import("@/views/challengeComparison.vue"),
  },

  // education page dropdown
  {
    path: "/trading-coach",
    name: "Trading Coach",
    component: () => import("@/views/tradingCoach.vue"),
  },

  {
    path: "/prime-academy",
    children: [
      {
        path: "",
        name: "Prime Academy",
        component: () => import("@/views/PrimeAcademy/index.vue"),
      },
      {
        path: ":slug",
        name: "Prime Academy Lesson",
        component: () => import("@/views/PrimeAcademy/lesson.vue"),
      }
    ],
  },
  // {
  //   path: "/quiz",
  //   name: "Quiz",
  //   component: () => import("@/views/quiz.vue"),
  // },
  {
    path: "/leaderboard",
    name: "Prime Leaderboard",
    component: () => import("@/views/primeLeaderboard.vue"),
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import("@/views/privacyPolicy.vue"),
  },
  {
    path: "/terms-conditions",
    name: "Terms & Conditions",
    component: () => import("@/views/termsCondition.vue"),
  },
  {
    path: "/risk-disclosure",
    name: "Risk Disclosure",
    component: () => import("@/views/riskDisclosure.vue"),
  },
  {
    path: "/anti-money-laundering-policy",
    name: "Anti-money laundering policy",
    component: () => import("@/views/antiMoneyLaundering.vue"),
  },

  {
    path: "/hours-payout",
    name: "Hours Payout",
    component: () => import("@/views/hoursPayout.vue"),
  },

  {
    path: "/lp",
    name: "Landing Page",
    component: () => import("@/views/lp/index.vue"),
  },

  // PAGE NOT FOUND
  {
    name: "PageNotFound",
    path: "/:pathMatch(.*)*",
    component: () => import("../views/PageNotFound.vue"),
    meta: {
      authRequired: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // linkActiveClass: "active",
  linkExactActiveClass: "nav-link active",
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

export default router;
